<template>
  <div ref="tradein-root" class="tradein-root" :class="customerClass">
    <slot name="tradein-styling"></slot>

    <div v-if="isLoading" class="is-loading">
      <div class="spinner">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
          <path
            d="M256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32V0zM422.3 352c-8.9 15.3-3.6 34.9 11.7 43.7s34.9 3.6 43.7-11.7L422.3 352zM256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
        </svg>
      </div>
    </div>
    <PopupDialog ref="popup" />
    <div ref="content" v-html="rawHtml" />
  </div>
</template>

<script>
import axios from "axios";
import store from "../store";
import { utilities } from "../utils.js";
import globalData from '../globaldata.js';
import vHelpInfoButton from "../directives/v-help-info-button.js";
import vClickOutside from "../directives/v-click-outside.js";
import PopupDialog from './PopupDialog.ce.vue';

export default {
  name: "TradeIn",

  components: {
    PopupDialog
  },

  directives: {
    'help-info-button': vHelpInfoButton,
    'click-outside': vClickOutside,
  },

  props: {
    customerClass: {
      type: String,
      default: "",
    },
    userReference: {
      type: String,
      required: true,
    },
    userToken: {
      type: String,
      required: false,
      default: ''
    },
    workflowId: {
      type: String,
      required: true,
    },
  },

  emits: ["navigateToPage"],

  setup() {
    return { store };
  },

  data: () => ({
    rawHtml: null,
    backendUrl: null,
    formListeners: [],
    isLoading: false,
    initialFormData: null,
  }),

  computed: {
    contentElement() {
      return this.$refs["content"];
    },
    rootElement() {
      return this.$refs["tradein-root"];
    },
    lastUrl() {
      return store.getters["vehicleInspection/lastUrl"];
    },
    registrationNumber() {
      return store.getters["vehicleInspection/registrationNumber"];
    },
    vehicleHash() {
      return store.getters["vehicleInspection/vehicleHash"];
    },
    inspectionHash() {
      return store.getters["vehicleInspection/inspectionHash"];
    },
    editionId() {
      return store.getters["vehicleInspection/editionId"];
    },
    prevStep() {
      return store.getters["general/prevStep"];
    }
  },

  created() {
    const workflowId = this.workflowId;
    const userReference = this.userReference;
    const userToken = this.userToken;

    const buildInfo = [
      // eslint-disable-next-line no-undef
      { label: 'Build Version:', value: APP_VERSION },
      // eslint-disable-next-line no-undef
      { label: 'Build Date:', value: BUILD_DATE },
      { label: 'Build Mode:', value: process.env.NODE_ENV },
    ];

    this.logLogo();
    this.logBuildInfo(buildInfo);

    store.dispatch("initialiseStore").then(async () => {

      await Promise.all([
        this.store.dispatch("auth/setWorkflowId", workflowId),
        this.store.dispatch("auth/setUserId", userReference),
        this.store.dispatch("auth/setUserToken", userToken)
      ]);

      this.loadHtml(null); // load the first form from the backend

      window.onpopstate = (event) => {
        if (event.state && event.state.backendUrl) {
          console.log(`going back to: ${event.state.backendUrl}`);
          this.loadHtml(event.state.backendUrl);
        }
      };
    })
  },

  mounted() {
    this.pullStylesFromSlot();
    this.$el.addEventListener("navigateToPage", async (e) => {
      // protect this call
      const newFormData = this.getSerializedFormData();
      const formDirty = !utilities.compareFormData(newFormData, this.initialFormData);
      if (formDirty) {

        const message = 'Ingevulde gegevens in deze stap zijn nog niet opgeslagen.<br/>Wilt u doorgaan zonder deze wijzigingen op te slaan?';
        const confirmLabel = 'Doorgaan zonder opslaan'
        const confirmCSSClasses = 'secondary'
        const result = await this.$refs.popup.openPopup('Waarschuwing', message, confirmLabel, confirmCSSClasses);

        if (!result) {
          return;
        }
      }
      const value = e.detail.getValue();
      this.loadHtml(value.url);
    });

    this.$el.addEventListener("submitMyForm", (e) => {
      if (this.contentElement) {
        // note that passing cancelable solves issue in firefox
        e.target
          .closest("form")
          .dispatchEvent(new CustomEvent("submit", { cancelable: true }));
      }
    });

    this.$el.addEventListener("someValueChanged", (e) => {
      const value = e.detail.getValue();
      const inputId = e.detail.inputId();
      if (inputId && this.contentElement) {
        const input = this.contentElement.querySelector(`#${inputId}`);
        if (input) {
          input.value = value;
          input.closest("form").dispatchEvent(
            new CustomEvent("change", {
              detail: {
                inputId: () => {
                  return inputId;
                },
                inputName: () => {
                  return input.name;
                },
              },
            })
          );
        }
      }
    });

    this.handleCalendarIconClicks();
  },

  methods: {
    /* Een slot met een stylesheet wordt blijkbaar niet standaard binnen de shadowroot
    meegenomen, dus even een hack functie om de stylesheet uit het slot te halen en
    in de component te jassen
    */
    pullStylesFromSlot() {
      const slots = this.$el.querySelector("slot[name=tradein-styling]");
      if (slots) {
        const styles = this.$el
          .querySelector("slot[name=tradein-styling]")
          .assignedNodes();
        for (let style of styles) {
          this.$el.append(style);
        }
      }
    },

    handleCalendarIconClicks() {
      // custom handle the click on the calendar icon
      // note that modern browsers support the showPicker method
      // for others we dispatch a mouseclick event on the date input
      this.contentElement.addEventListener("click", (event) => {
        if (event.target.classList.contains("icon-calendar")) {
          event.preventDefault();
          const dateInput = event.target.previousElementSibling;
          if ("showPicker" in HTMLInputElement.prototype) {
            dateInput.showPicker();
          } else {
            const clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
            });
            dateInput.dispatchEvent(clickEvent);
          }
        }
      });
    },

    initializeConditionals() {
      if (this.contentElement) {
        const forms = this.contentElement.getElementsByTagName("form");
        for (const form of forms) {
          utilities.evaluateConditionals(form);
          utilities.evaluateExpression(form);
          form.addEventListener("change", (event) => {
            utilities.evaluateConditionals(form);
            utilities.evaluateCopyData(form, event);
            utilities.evaluateExpression(form, event);
          });
        }
      }
    },

    readCsrfFromForm() {
      if (this.contentElement) {
        const forms = this.contentElement.getElementsByTagName("form");
        for (const form of forms) {
          const csrfElement = form.querySelector(
            "input[name='csrfmiddlewaretoken']"
          );
          this.store.dispatch("general/setCsrfToken", csrfElement.value);
        }
      }
    },

    interceptRestart() {
      /* Note that this is a hack.. should be done properly with a directive? */
      const input = this.contentElement.querySelector("a.tradein-follow-link");
      if (input) {
        input.addEventListener("click", (event) => {
          event.preventDefault();
          this.loadHtml(input.href);
        });
      }
    },

    applyDirectives() {
      const directives = ['help-info-button', 'click-outside'];
      directives.forEach((directiveName) => {
        const elements = this.contentElement.querySelectorAll(`[v-${directiveName}]`);
        elements.forEach((el) => {
          const directive = this.$options.directives[`${directiveName}`];
          const value = el.getAttribute(`v-${directiveName}`);
          if (typeof directive.beforeMount === 'function') {
            directive.beforeMount(el, { value: value });
          }
          if (typeof directive.mounted === 'function') {
            directive.mounted(el, { value: value });
          }
        });
      });
    },

    getSerializedFormData() {
      const mergedFormData = new FormData();
      if (this.contentElement) {
        const forms = this.contentElement.getElementsByTagName("form");
        for (const form of forms) {
          const formData = new FormData(form);
          for (const [key, value] of formData.entries()) {
            mergedFormData.append(key, value);
          }
        }
      }
      return mergedFormData;
    },

    storeInitialFormData() {
      setTimeout(() => {
        this.initialFormData = this.getSerializedFormData();
      }, 0)
    },

    waitForTimeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    safeScrollIntoView(element) {
      // take height of static progress-bar into account
      const rect = element.getBoundingClientRect();

      let menuBarHeight = 0;
      if (this.contentElement) {
        const progressBar = this.contentElement.querySelector('.progress-bar');
        if (progressBar) {
          menuBarHeight = progressBar.offsetHeight;
        }
      }

      const scrollY = window.scrollY + rect.top - menuBarHeight;

      // Scroll to the adjusted position
      window.scrollTo({
        top: scrollY,
        behavior: 'auto',
      });

    },

    jumpToErrorOrTop() {
      if (this.contentElement) {
        // make sure the browser scrolls to the first error or to the top of the form
        const errorElement = this.contentElement.querySelector('.error, .errorField, .help-inline');
        const tgtElement = errorElement ? errorElement : this.contentElement;

        // small wait, so dynamic content is rendered
        this.waitForTimeout(1).then(() => {
          this.safeScrollIntoView(tgtElement);
        });
      }
    },

    navToPreviousStep() {
      if (this.prevStep) {
        this.$emit("navigateToPage", this.prevStep);
        const event = new CustomEvent("navigateToPage", {
          bubbles: true,
          composed: true,
          detail: {
            getValue: () => {
              return this.prevStep;
            },
          },
        });
        this.$el.dispatchEvent(event);
      }
    },

    interceptSubmission() {
      if (this.contentElement) {
        const forms = this.contentElement.getElementsByTagName("form");
        for (const form of forms) {
          // we valideren alles in de backend zodat we consistentere foutmeldingen kunnen geven
          form.noValidate = true;
          form.addEventListener(
            "submit",
            (e) => {
              e.preventDefault();

              if (e.submitter && e.submitter.name === 'previous') {
                // We willen hier geen echte submit doen, maar gewoon een GET naar de vorige stap
                this.navToPreviousStep();
                return;
              }

              const formData = new FormData(form);
              formData.append("user-reference", this.userReference);
              formData.append("user-token", this.userToken);
              // post to the backend
              if (this.backendUrl) {
                this.isLoading = true; // should we add axios interception?

                globalData.cancelToken.cancel('All requests canceled');
                globalData.resetCancelToken();

                axios
                  .post(this.backendUrl, formData)
                  .then((response) => {
                    // console.log(`adding to hist: ${this.lastUrl}`);
                    // window.history.pushState(
                    //   { backendUrl: this.lastUrl },
                    //   document.title
                    // );

                    // TODO: should we remove existing form listeners?
                    this.backendUrl = response.request.responseURL; // the redirected URL
                    this.store.commit(
                      "vehicleInspection/setLastUrl",
                      this.backendUrl
                    );

                    this.rawHtml = response.data.html_form;
                    this.isLoading = false;

                    this.$nextTick(() => {
                      // wait for rendering the view to safely access or query the DOM. ¯\_(ツ)_/¯
                      this.readCsrfFromForm();
                      this.interceptSubmission();
                      this.initializeConditionals();
                      this.interceptRestart();
                      this.applyDirectives();
                      this.storeInitialFormData();
                      this.jumpToErrorOrTop();
                      this.isLoading = false;
                    });
                  })
                  .catch(() => {
                    this.isLoading = false;
                  });
              }
            },
            { once: false }  /* Werkt om 1 of andere reden niet goed in firefox */
          );
        }
      }
    },

    loadHtml(url) {
      if (!url) {
        url = this.lastUrl;
      }
      if (!url) {
        url = `${import.meta.env.VITE_APP_CLIENT_GATEWAY_URL}${import.meta.env.VITE_APP_TRADEIN_BASE_URI
          }${import.meta.env.VITE_APP_TRADEIN_MAIN_URI}/`;
      }

      // if (addToHistory && this.url) {
      //   console.log(`adding to hist: ${this.url}`);
      //   window.history.pushState({ backendUrl: this.url }, document.title);
      // }

      const urlWithCacheBuster = utilities.addCacheBusterToUrl(url);

      this.isLoading = true; // should we add axios interception?
      axios
        .get(urlWithCacheBuster)
        .then((response) => {
          this.backendUrl = response.request.responseURL; // the redirected URL

          this.store.commit("vehicleInspection/setLastUrl", this.backendUrl);

          this.rawHtml = response.data.html_form;
          this.isLoading = false;

          this.$nextTick(() => {
            // wait for rendering the view to safely access or query the DOM.
            if (this.contentElement) {
              this.readCsrfFromForm();
              this.interceptSubmission();
              this.interceptRestart();
              this.initializeConditionals();
              this.applyDirectives();

              const forms = this.contentElement.getElementsByTagName("form");
              for (const form of forms) {
                utilities.evaluateConditionals(form);
              }
              this.storeInitialFormData();
              this.jumpToErrorOrTop();
            }
          });
        })
        .catch((reason) => {
          this.isLoading = false;
          console.error(`Error loading html: ${reason.message}`);
          this.rawHtml = `<p>Probleem bij laden HTML. Probeer het nogmaals..`;
          this.jumpToErrorOrTop();
        });
    },


    logLogo() {
      const logo = `
   ___   _____ ___     ______               __           ____
  / _ ) / ___// _ |   /_  __/____ ___ _ ___/ /___  ____ /  _/___
 / _  |/ /__ / __ |    / /  / __// _ \`// _  // -_)/___/_/ / / _ \\
/____/ \\___//_/ |_|   /_/  /_/   \\_,_/ \\_,_/ \\__/     /___//_//_/
`;
      console.log(logo);
    },

    logBuildInfo(rows) {

      const labelWidth = 20; // Adjust this to the desired label column width
      const valueWidth = 30; // Adjust this to the desired value column width

      // Function to create a cell with left alignment and padding on the left side
      function createCellLeftAlign(text, width, paddingLeft = 1) {
        return ' '.repeat(paddingLeft) + text.padEnd(width - paddingLeft).substring(paddingLeft - 1);
      }

      function drawRow(label, value) {
        console.log(`║${createCellLeftAlign(label, labelWidth)}║${createCellLeftAlign(value, valueWidth)}║`);
      }

      console.log(`╔${'═'.repeat(labelWidth)}╦${'═'.repeat(valueWidth)}╗`);

      rows.forEach((row, index) => {
        if (index > 0) {
          console.log(`╠${'═'.repeat(labelWidth)}╬${'═'.repeat(valueWidth)}╣`);
        }
        drawRow(row.label, row.value);
      });

      console.log(`╚${'═'.repeat(labelWidth)}╩${'═'.repeat(valueWidth)}╝`);
    }
  }

};
</script>

<style lang="scss">
@import "../scss/tradein.scss";
@import "../scss/customers/justlease.scss";
@import "../scss/customers/auto-nl.scss";
@import "../scss/customers/ayvens.scss";
@import "../scss/customers/bca.scss";
</style>
