<template>
  <div class="single-image-upload">

    <div v-if="!image" class="image add-image">
      <label class="placeholder-image">
        <i :class="`${inputId}`"></i>

        <div class="text">
          <span>{{ label }}</span>
        </div>

        <p v-if="placeholder" class="extra-text">
          {{ placeholder }}
        </p>

        <input :id="`single-image-upload-${inputId}`" ref="fileInput" class="hidden" type="file" accept="image/*"
          title="" capture @change="onFileChange" />
      </label>
    </div>

    <div v-if="image" class="image">
      <div class="image-wrapper">
        <img v-load-image class="inspection-image" :alt="image.name" :data-src="image.data" />

        <div v-if="image && image.isUploading" class="spinner-wrapper">
          <i class="spinner">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32V0zM422.3 352c-8.9 15.3-3.6 34.9 11.7 43.7s34.9 3.6 43.7-11.7L422.3 352zM256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
            </svg>
          </i>
        </div>

        <div v-if="image" class="remove" @click.stop="removeImage()">
          <div class="button">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utilities } from "../utils.js";
import { UploadedImage } from "../models/uploaded-image.js";
import axios from "axios";
import vLoadImage from "../directives/v-load-image.js";
import store from "../store";
import globalData from '../globaldata.js';


export default {
  name: "SingleImageUpload",
  directives: {
    loadImage: vLoadImage,
  },
  props: {
    inputId: {
      type: String,
      required: true,
    },
    required: {
      type: String,
      default: "false",
    },
    label: {
      type: String,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select a photo",
    },
    value: {
      type: String, // json
      default: null,
    },
  },
  emits: ["change"],

  data: () => ({
    image: null,
  }),

  computed: {
    csrfToken() {
      return store.getters["general/csrfToken"];
    },
  },

  watch: {
    image: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue) {
        const val = this.getEmitPayload()
        this.$emit("change", val);
        utilities.emitValueChanged(this.$el, this.inputId, val);
        if (this.image && !this.image.url) {
          this.uploadImage();
        }
      },
      deep: true,  // belangrijk. anders wordt wijziging van bijv URL niet opgepikt
    },
  },

  mounted() {
    let parsedValue = {};
    if (this.value) {
      parsedValue = JSON.parse(this.value) || {};

      const new_images = {};
      for (let [img_id, img_val] of Object.entries(parsedValue)) {
        if (img_val["url"]) {
          const img = new UploadedImage(img_id, img_val["url"]);
          img.url = img_val["url"];
          new_images[img_id] = img;
        }
      }
      let images_array = Object.values(new_images)
      if (images_array.length > 0) {
        this.image = images_array[0];
      }
    }
  },

  methods: {

    getEmitPayload() {
      const newValues = {};
      if (this.image) {
        newValues[this.image.key] = this.image.payloadForInput;
      }
      return JSON.stringify(newValues);
    },

    uploadImage() {
      const url = utilities.fix_url_double_slashes(
        `${import.meta.env.VITE_APP_CLIENT_GATEWAY_URL}${this.imageUrl}`
      );

      Object.values({ image: this.image })
        .filter((img) => !!img)
        .filter((img) => !img.url)
        .filter((img) => !!img.uploadPayload.data)
        .forEach((img) => {
          if (!img.isUploading) {
            img.isUploading = true;

            const payload = {
              ...img.uploadPayload,
              csrfmiddlewaretoken: this.csrfToken,
            };

            axios.post(url, payload, {
              cancelToken: globalData.cancelToken.token,
            }).then((response) => {
              img.name = response["data"]["id"];
              img.url = response["data"]["url"];
              img.isUploading = false;
              // TODO:
              // - progress
              // - get generated filename + replace src
            }).catch((error) => {
              if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
                this.deleteImageFromMemory();
              } else {
                console.log(`Error: ${error.message} - ${error.response.data?.message}`);
                this.image = null;
              }
            });
          }
        })
    },

    deleteImageFromMemory() {
      this.image = null;
      if (this.$refs["fileInput"]) {
        // not there because of v-if statement
        this.$refs["fileInput"]["value"] = null;
      }
    },

    removeImage() {
      if (this.image.url) {
        const url = utilities.fix_url_double_slashes(
          `${import.meta.env.VITE_APP_CLIENT_GATEWAY_URL}${this.imageUrl}`
        );

        axios.delete(url, { data: this.image.removePayload }).then(() => {
          this.deleteImageFromMemory();
        });
      } else {
        this.deleteImageFromMemory();
      }
    },

    onFileChange(e) {
      let readers = [];
      for (const selectedFile of e.target.files) {
        let reader = new FileReader();
        readers.push(reader);
        reader.onload = (e) => {
          const imageData = e.target.result;
          const img = new UploadedImage(selectedFile.name, imageData);
          this.image = img;
        };
        reader.readAsDataURL(selectedFile);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/components/_singleimageupload.scss";
</style>
